<template>
  <v-container>
    <v-card class="pt-2" elevation="0">
      <div class="d-flex">
        <v-btn rounded depressed color="green" class="white--text ml-2" @click="$router.push(`/createAccountingEntry`)"
          v-if="returnAbility('entry:store')">
          <v-icon>mdi-plus</v-icon>
          {{ $t('add a restriction') }}
        </v-btn>
      </div>
      <app-accounting-entry-filter @filterTable="applySearch($event)"
        @resetFilter="resetFilterObject"></app-accounting-entry-filter>
    </v-card>
    <v-container id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!loading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
          <template v-for="data in accountingEntries">
            <tr :key="data.uid" style="background: var(--group-by-col)">
              <th style="border: 0; font-size: 1rem">
                <span> {{ data.date }} </span>
              </th>
              <th style="border: 0; font-size: 1rem">
                <span>الرقم: {{ data.id }} </span>
              </th>
              <th style="border: 0; font-size: 1rem">
                <span> {{ $t('user name') }}: {{ data.user.name }} </span>
              </th>
              <th style="border: 0; font-size: 1rem">
                <span v-if="data.source">المصدر: {{ data.source }} </span>
              </th>
              <th></th>
              <th style="border: 0; font-size: 1rem" >
                <v-icon v-if="returnAbility('entry:update') && (data.operate)" color="info"
                  @click="$router.push(`/editAccountingEntry/${data.id}`)">mdi-pencil</v-icon>
                  <v-icon v-if="returnAbility('entry:show') && (!data.operate) && (data.owner_type != null)" 
                  color="green" @click="goTodestination(data.owner_type,data.owner_id,data.owner_type_id)">mdi-eye</v-icon>
                <app-delete-entry v-if="displayDeleteIcon && data.operate" :record="data" :serverRoute="'/accounting/entry'"
                  @record-deleted="fetchAccountingEntry"></app-delete-entry>
              </th>
            </tr>
            <tr v-for="(item, idx) in data.rows" :key="idx + item.id + item.type">
              <td v-if="item.type == 'debit'">
                {{ item.amount.toLocaleString() }}
              </td>
              <td v-if="item.type == 'debit'"></td>
              <td v-if="item.type == 'credit'"></td>
              <td v-if="item.type == 'credit'">
                {{ item.amount.toLocaleString() }}
              </td>
              <td>{{ item.account.name }}</td>
              <td>
                <span v-if="item.desc">{{ item.desc }}</span>
              </td>
              <td>
                <span v-if="item.sub_account">{{ item.sub_account.name }}</span>
              </td>
              <td>
                <span v-if="item.cost_center">{{ item.cost_center.name }}</span>
              </td>
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
      <div class="d-flex justify-end mt-5">
        <app-pagination v-if="!loading" :totalPages="totalTablePages" :page="filterObject.page"
          @PaginationValue="applyPagination($event)"></app-pagination>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import spinner from "../../../../components/base/spinner.vue";
import accountingEntryFilter from "./accountingEntryFilter.vue";
import axios from "axios";
import pagination from "../../../../components/base/pagination.vue";
import deleteEntry from "./deleteAccountingEntry.vue";
export default {
  components: {
    appSpinner: spinner,
    appDeleteEntry: deleteEntry,
    appAccountingEntryFilter: accountingEntryFilter,
    appPagination: pagination,
  },
  data() {
    return {
      tableHeaders: ["debtor", "credit", "hr.employee.check.account", "explain","Sub Account", "cost center"],
      accountingEntries: [],
      totalTablePages: null,
      loading: false,
      filterObject: {
        user: null,
        id: null,
        debit: null,
        credit: null,
        desc: "",
        costCenter: null,
        from_date: "",
        to_date: "",
        account: null,
      },
    };
  },
  methods: {
    goTodestination(dis,id,type){
      if(dis=="VOUCHER"){
        this.$router.push(`/edit-document/${id}?document_id=${type}`);
      }
      if(dis=="FINANCIALORDER"){
        this.$router.push(`/editAccountingEntry/${id}`);
      }
      if(dis=="INVOICE"){
        this.$router.push(`/edit-invoice/${id}?invoice_id=${type}`);
      }
    },
    resetFilterObject() {
      this.filterObject.user = null;
      this.filterObject.id = null;
      this.filterObject.debit = null;
      this.filterObject.credit = null;
      this.filterObject.desc = "";
      this.filterObject.costCenter = null;
      this.filterObject.from_date = "";
      this.filterObject.to_date = "";
      this.filterObject.account = null;
    },
    applySearch(filterObject) {
      this.filterObject = filterObject;
      this.fetchAccountingEntry();
    },
    applyPagination(paginationValue) {
      this.filterObject.page = paginationValue;
      this.fetchAccountingEntry();
    },
    async fetchAccountingEntry() {
      try {
        this.loading = true;
        if (!this.filterObject.user) delete this.filterObject.user;
        if (!this.filterObject.id) delete this.filterObject.id;
        if (!this.filterObject.debit) delete this.filterObject.debit;
        if (!this.filterObject.credit) delete this.filterObject.credit;
        if (!this.filterObject.desc) delete this.filterObject.desc;
        if (!this.filterObject.costCenter) delete this.filterObject.costCenter;
        if (!this.filterObject.from_date) delete this.filterObject.from_date;
        if (!this.filterObject.to_date) delete this.filterObject.to_date;
        if(this.$route.params.id){
          this.filterObject.id = this.$route.params.id;
        }
        this.filterObject.perPage = 10;
        const entries = await axios.get("/accounting/entry", {
          params: this.filterObject,
        });
        this.accountingEntries = entries.data.data;
        this.totalTablePages = entries.data.meta.total;
        this.filterObject.page = entries.data.meta.current_page;
        this.accountingEntries.forEach((item) => {
          item.uid = this.$uuid.v1();
        });
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (this.returnAbility("entry:delete")) {
      this.displayDeleteIcon = true;
    }
    this.fetchAccountingEntry();
  },
};
</script>

<style>

</style>